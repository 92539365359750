export default {
    "types": {
        "basic": {
            "showName": "Библиотека KPI"
        }
    },
    "element_edit_dialog_width": 80,
    "use_scales_units_library": false,
    "folder_moderators_config_enabled": true,
    "element_change_propagation_mode": 2,
    "elements_search_fields": [
        "library_elements.name",
        "cast(library_elements.id as text)"
    ],
    "elements_table_columns": [
        {
            "label": "ID",
            "prop": "id",
            "width": 70
        },
        {
            "label": "Наименование КПЭ",
            "template": "components/tableColumnTemplates/library/ElementNameTableColumn",
            "bind": {
                "scope": "scope",
                "show-folders-in-search": "templateData.$parent.$parent.$data.showFoldersInSearch"
            }
        },
        {
            "label": "Описание КПЭ",
            "template": "components/CollapseTextWithTooltip",
            "bind": {
                "text": "scope.row.desc"
            },
            "width": 200
        },
        {
            "label": "Методика расчета КПЭ",
            "template": "components/CollapseTextWithTooltip",
            "bind": {
                "text": "scope.row.scale.method_calc_kpi"
            },
            "width": 200
        },
        {
            "label": "Единица измерения",
            "prop": "scale.unit",
            "width": 200
        },
        {
            "label": "Шкала",
            "template": "componentsCompany/cherkizovo/tableColumnTemplates/library/ElementScaleNameTableColumn",
            "bind": {
                "scope": "scope"
            },
            "width": 200
        }
    ]
}