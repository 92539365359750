export default {
    "sectionsBlockHeaderTemplate": "componentsCompany/cherkizovo/CardSectionsBlockHeader",
    "sections": {
        "basic": {
            "showName": "Библиотечные цели. Выбираются из общей библиотеки",
            "emptyText": "Нет целей",
            "fromLibrary": "basic",
            "fieldsAvailableForEdit": {
                "weight": {
                    "stages": [
                        "plan",
                        "correction"
                    ],
                    "rules": [
                        "app\\build\\cherkizovo\\rbac\\businessRules\\ElementNotInClosedRouteCardRule",
                        "app\\build\\cherkizovo\\rbac\\businessRules\\CherkizovoCardNotDisabledRule"
                    ]
                },
                "scale.current_part_id": {
                    "stages": [
                        "plan",
                        "correction"
                    ],
                    "rules": [
                        "app\\build\\cherkizovo\\rbac\\businessRules\\ElementNotInClosedRouteCardRule",
                        "app\\build\\cherkizovo\\rbac\\businessRules\\CherkizovoCardNotDisabledRule"
                    ]
                },
                "skip_weight_validation": {
                    "rules": [
                        "app\\build\\cherkizovo\\rbac\\businessRules\\ElementNotInClosedRouteCardRule",
                        "app\\build\\cherkizovo\\rbac\\businessRules\\CherkizovoCardNotDisabledRule"
                    ]
                },
                "comment.plan": {
                    "stages": [
                        "plan",
                        "correction"
                    ],
                    "skipElementEditPermissionsCheck": true
                },
                "comment.assessment": {
                    "stages": [
                        "assessment"
                    ],
                    "skipElementEditPermissionsCheck": true
                }
            }
        },
        "manual": {
            "showName": "Личные цели. Формируются самостоятельно",
            "allowedScaleTypes": [
                "design"
            ],
            "emptyText": "Нет целей",
            "columnsConfig": "manual_columns",
            "fieldsAvailableForEdit": {
                "name": {
                    "stages": [
                        "plan",
                        "correction"
                    ],
                    "rules": [
                        "app\\build\\cherkizovo\\rbac\\businessRules\\ElementNotInClosedRouteCardRule",
                        "app\\build\\cherkizovo\\rbac\\businessRules\\CherkizovoCardNotDisabledRule"
                    ]
                },
                "desc": {
                    "stages": [
                        "plan",
                        "correction"
                    ],
                    "rules": [
                        "app\\build\\cherkizovo\\rbac\\businessRules\\ElementNotInClosedRouteCardRule",
                        "app\\build\\cherkizovo\\rbac\\businessRules\\CherkizovoCardNotDisabledRule"
                    ]
                },
                "weight": {
                    "stages": [
                        "plan",
                        "correction"
                    ],
                    "rules": [
                        "app\\build\\cherkizovo\\rbac\\businessRules\\ElementNotInClosedRouteCardRule",
                        "app\\build\\cherkizovo\\rbac\\businessRules\\CherkizovoCardNotDisabledRule"
                    ]
                },
                "skip_weight_validation": {
                    "stages": [
                        "plan",
                        "correction"
                    ],
                    "rules": [
                        "app\\build\\cherkizovo\\rbac\\businessRules\\ElementNotInClosedRouteCardRule",
                        "app\\build\\cherkizovo\\rbac\\businessRules\\CherkizovoCardNotDisabledRule"
                    ]
                },
                "scale.parent_id": {
                    "stages": [
                        "plan",
                        "correction"
                    ],
                    "rules": [
                        "app\\build\\cherkizovo\\rbac\\businessRules\\ElementNotInClosedRouteCardRule",
                        "app\\build\\cherkizovo\\rbac\\businessRules\\CherkizovoCardNotDisabledRule"
                    ]
                },
                "scale.current_part_id": {
                    "stages": [
                        "plan",
                        "correction"
                    ],
                    "rules": [
                        "app\\build\\cherkizovo\\rbac\\businessRules\\ElementNotInClosedRouteCardRule",
                        "app\\build\\cherkizovo\\rbac\\businessRules\\CherkizovoCardNotDisabledRule"
                    ]
                },
                "scale.unit": {
                    "stages": [
                        "plan",
                        "correction"
                    ],
                    "rules": [
                        "app\\build\\cherkizovo\\rbac\\businessRules\\ElementNotInClosedRouteCardRule",
                        "app\\build\\cherkizovo\\rbac\\businessRules\\CherkizovoCardNotDisabledRule"
                    ]
                },
                "scale.currentScalePart.pay_100": {
                    "stages": [
                        "plan",
                        "correction"
                    ],
                    "rules": [
                        "app\\build\\cherkizovo\\rbac\\businessRules\\ElementNotInClosedRouteCardRule",
                        "app\\build\\cherkizovo\\rbac\\businessRules\\CherkizovoCardNotDisabledRule"
                    ]
                },
                "scale.currentScalePart.pay_75": {
                    "stages": [
                        "plan",
                        "correction"
                    ],
                    "rules": [
                        "app\\build\\cherkizovo\\rbac\\businessRules\\ElementNotInClosedRouteCardRule",
                        "app\\build\\cherkizovo\\rbac\\businessRules\\CherkizovoCardNotDisabledRule"
                    ]
                },
                "scale.currentScalePart.pay_50": {
                    "stages": [
                        "plan",
                        "correction"
                    ],
                    "rules": [
                        "app\\build\\cherkizovo\\rbac\\businessRules\\ElementNotInClosedRouteCardRule",
                        "app\\build\\cherkizovo\\rbac\\businessRules\\CherkizovoCardNotDisabledRule"
                    ]
                },
                "scale.currentScalePart.fact": {
                    "stages": [
                        "assessment"
                    ],
                    "rules": [
                        "app\\build\\cherkizovo\\rbac\\businessRules\\ElementNotInClosedRouteCardRule",
                        "app\\build\\cherkizovo\\rbac\\businessRules\\CherkizovoCardNotDisabledRule"
                    ]
                },
                "comment.plan": {
                    "stages": [
                        "plan",
                        "correction"
                    ],
                    "rules": [
                        "app\\build\\cherkizovo\\rbac\\businessRules\\ElementNotInClosedRouteCardRule"
                    ],
                    "skipElementEditPermissionsCheck": true
                },
                "comment.assessment": {
                    "stages": [
                        "assessment"
                    ],
                    "rules": [
                        "app\\build\\cherkizovo\\rbac\\businessRules\\ElementNotInClosedRouteCardRule"
                    ],
                    "skipElementEditPermissionsCheck": true
                }
            }
        }
    },
    "default_columns": [
        {
            "label": "Название цели",
            "minWidth": 200,
            "template": "components/tableColumnTemplates/card/ElementNameTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            }
        },
        {
            "label": "Вес",
            "width": 100,
            "template": "componentsCompany/cherkizovo/tableColumnTemplates/card/ElementWeightWithDisableTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged(); }"
            }
        },
        {
            "label": "Шкала",
            "width": 140,
            "template": "componentsCompany/cherkizovo/tableColumnTemplates/card/ElementScaleTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged(); }"
            }
        },
        {
            "label": "Парт",
            "manualColumnTemplate": true,
            "template": "componentsCompany/cherkizovo/tableColumnTemplates/card/parts/ElementScalePartTopTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            }
        },
        {
            "label": "Единица измерения",
            "width": 120,
            "template": "componentsCompany/cherkizovo/tableColumnTemplates/card/ElementScaleUnitTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            }
        },
        {
            "label": "Ключевые результаты выполнения КПЭ на 100%",
            "width": 150,
            "template": "componentsCompany/cherkizovo/tableColumnTemplates/card/ElementScalePayTableColumn",
            "bind": {
                "scope": "scope",
                "target-index": "\"pay_100\""
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            }
        },
        {
            "label": "Ключевые результаты выполнения КПЭ на 75%",
            "width": 150,
            "template": "componentsCompany/cherkizovo/tableColumnTemplates/card/ElementScalePayTableColumn",
            "bind": {
                "scope": "scope",
                "target-index": "\"pay_75\""
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            }
        },
        {
            "label": "Ключевые результаты выполнения КПЭ на 50%",
            "width": 150,
            "template": "componentsCompany/cherkizovo/tableColumnTemplates/card/ElementScalePayTableColumn",
            "bind": {
                "scope": "scope",
                "target-index": "\"pay_50\""
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            }
        },
        {
            "label": "Плановое значение",
            "width": 100,
            "template": "componentsCompany/cherkizovo/tableColumnTemplates/card/ElementScalePlanTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            }
        },
        {
            "label": "Комментарий к цели",
            "width": 120,
            "template": "components/tableColumnTemplates/card/ElementCommentsTableColumn",
            "bind": {
                "scope": "scope",
                "stage": "'plan'",
                "last-comment": "scope.row.last_comment_by_stage['plan']",
                "comments-count": "scope.row.comments_count_by_stage['plan']"
            }
        },
        {
            "label": "Фактическое значение",
            "width": 120,
            "showOnStages": [
                "assessment"
            ],
            "showOnCompleted": true,
            "template": "componentsCompany/cherkizovo/tableColumnTemplates/card/ElementFactTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            }
        },
        {
            "label": "Комментарий к факту",
            "width": 120,
            "showOnStages": [
                "assessment"
            ],
            "showOnCompleted": true,
            "template": "components/tableColumnTemplates/card/ElementCommentsTableColumn",
            "bind": {
                "scope": "scope",
                "stage": "'assessment'",
                "last-comment": "scope.row.last_comment_by_stage['assessment']",
                "comments-count": "scope.row.comments_count_by_stage['assessment']"
            }
        },
        {
            "label": "Взвешенный % выплат по цели",
            "showOnStages": [
                "assessment"
            ],
            "showOnCompleted": true,
            "prop": "bonus",
            "width": 100
        },
        {
            "label": "Ответственный за KPI",
            "width": 120,
            "prop": "scale.responsible"
        },
        {
            "label": "ID",
            "width": 40,
            "prop": "id"
        },
        {
            "label": "ID Парт",
            "width": 60,
            "prop": "scale.current_part_id"
        },
        {
            "label": "",
            "template": "components/tableColumnTemplates/card/ElementActionsTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            },
            "align": "'center'",
            "width": 50
        }
    ],
    "manual_columns": [
        {
            "label": "Название цели",
            "minWidth": 200,
            "template": "components/tableColumnTemplates/card/ElementNameTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            }
        },
        {
            "label": "Вес",
            "width": 100,
            "template": "componentsCompany/cherkizovo/tableColumnTemplates/card/ElementWeightWithDisableTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged(); }"
            }
        },
        {
            "label": "Шкала",
            "width": 140,
            "template": "componentsCompany/cherkizovo/tableColumnTemplates/card/ElementScaleTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged(); }"
            }
        },
        {
            "label": "Парт",
            "width": 560,
            "manualColumnTemplate": true,
            "template": "componentsCompany/cherkizovo/tableColumnTemplates/card/parts/ElementScaleManualPartTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged(); }"
            }
        },
        {
            "label": "Единица измерения",
            "width": 120,
            "template": "componentsCompany/cherkizovo/tableColumnTemplates/card/ElementScaleUnitTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            }
        },
        {
            "label": "Ключевые результаты выполнения КПЭ на 100%",
            "width": 150,
            "template": "componentsCompany/cherkizovo/tableColumnTemplates/card/ElementScalePayTableColumn",
            "bind": {
                "scope": "scope",
                "target-index": "\"pay_100\""
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            }
        },
        {
            "label": "Ключевые результаты выполнения КПЭ на 75%",
            "width": 150,
            "template": "componentsCompany/cherkizovo/tableColumnTemplates/card/ElementScalePayTableColumn",
            "bind": {
                "scope": "scope",
                "target-index": "\"pay_75\""
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            }
        },
        {
            "label": "Ключевые результаты выполнения КПЭ на 50%",
            "width": 150,
            "template": "componentsCompany/cherkizovo/tableColumnTemplates/card/ElementScalePayTableColumn",
            "bind": {
                "scope": "scope",
                "target-index": "\"pay_50\""
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            }
        },
        {
            "label": "Плановое значение",
            "width": 100,
            "template": "componentsCompany/cherkizovo/tableColumnTemplates/card/ElementScalePlanTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            }
        },
        {
            "label": "Комментарий к цели",
            "width": 120,
            "template": "components/tableColumnTemplates/card/ElementCommentsTableColumn",
            "bind": {
                "scope": "scope",
                "stage": "'plan'",
                "last-comment": "scope.row.last_comment_by_stage['plan']",
                "comments-count": "scope.row.comments_count_by_stage['plan']"
            }
        },
        {
            "label": "Фактическое значение",
            "width": 120,
            "showOnStages": [
                "assessment"
            ],
            "showOnCompleted": true,
            "template": "componentsCompany/cherkizovo/tableColumnTemplates/card/ElementFactTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            }
        },
        {
            "label": "Комментарий к факту",
            "width": 120,
            "showOnStages": [
                "assessment"
            ],
            "showOnCompleted": true,
            "template": "components/tableColumnTemplates/card/ElementCommentsTableColumn",
            "bind": {
                "scope": "scope",
                "stage": "'assessment'",
                "last-comment": "scope.row.last_comment_by_stage['fact']",
                "comments-count": "scope.row.comments_count_by_stage['fact']"
            }
        },
        {
            "label": "Взвешенный % выплат по цели",
            "showOnStages": [
                "assessment"
            ],
            "showOnCompleted": true,
            "prop": "bonus",
            "width": 100
        },
        {
            "label": "Ответственный за KPI",
            "width": 120,
            "prop": "scale.responsible"
        },
        {
            "label": "ID",
            "width": 40,
            "prop": "id"
        },
        {
            "label": "",
            "template": "components/tableColumnTemplates/card/ElementActionsTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            },
            "align": "'center'",
            "width": 50
        }
    ]
}